import { observer } from 'mobx-react-lite'
import { Model } from './Model'
import question from './assets/question.svg'
import questionDark from './assets/question-dark.svg'
import { useEffect, useState } from 'react'
import { Address } from '@ton/core'
import { useTonClient } from './contract/useTonClient'
import { useTonAddress, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react'
import copy from './assets/copy.svg'
import checkWhite from './assets/check-white.svg'
import * as Contract from './contract/Stake'

interface Props {
    model: Model
}

const Stats = observer(({ model }: Props) => {
    let copiedTimeout: ReturnType<typeof setTimeout>

    const { getUserInfo, sendMessage, getGetApy, getGetTimeStep, getGetTotalStaked } = Contract.stakeTon()
    const [userData, setUserData] = useState<any>()
    const [contractBalance, setContractBalance] = useState('0.00')
    const [totalStaked, setTotalStaked] = useState('0.00')
    const [totalClaim, setTotalClaim] = useState('0.00000')
    const client = useTonClient()
    const [tonConnectUI] = useTonConnectUI()
    const wallet = useTonWallet()
    const [isWalletConnected, setIswalletConnected] = useState(false)
    const [refferalLink, setRefferalLink] = useState('')
    const address = useTonAddress()
     
    const getContractBalance = async () => {
        try {
            const balance = await client.getBalance(Address.parse(import.meta.env.VITE_CONTRACT_ADDRESS))
            setContractBalance((Number(balance)/1e9).toString())
        } catch (error) {
            console.log(error)
        }
    }

    const getTotalStaked = async () => {
        try {
            const balance = await getGetTotalStaked()
            setTotalStaked((Number(balance) / 1e9).toFixed(4) + ' Ton')
        } catch (error) {
            console.log(error)
        }
    }

    const getUserData = async () => {
        try {
            const addressss = Address.parse(address)
            const result = await getUserInfo(addressss)
            setUserData(result)
            return result
        } catch (error) {
            console.log(error)
        }
    }

    const convertAmount = (amount: BigInt) => {
        return (Number(amount) / 1e9).toFixed(6)
    }

    const referralUrl = () => {
        const currentUrl = new URL(document.URL)
        let url = `${currentUrl.origin}/?refferal=${Address.parse(wallet.account.address).toString()}`
        setRefferalLink(url)
    }

    const handleCopyRefLink = async () => {
        try {
            await navigator.clipboard.writeText(refferalLink)
        } catch (e) {
            console.log()
        }
    }

    const calculationOfStake = async () => {
        try {
            const userData = await getUserData()
            if (!userData) return
            const APY = await getGetApy()
            const TIME_STEP = await getGetTimeStep()
            const currentTime = Math.floor(Date.now() / 1000) // Current time in seconds
            const stakeAge = currentTime - Number(userData.lastClaimTimestamp)
            if (Number(userData.totalStaked) / 1e9 == 0) {
                setTotalClaim((Number(userData.availableToWithdraw) / 1e9).toFixed(6))
            }
            const reward = ((Number(userData.totalStaked) / 1e9) * Number(APY) * stakeAge) / (1000 * Number(TIME_STEP))
            setTotalClaim((Number(userData.availableToWithdraw) / 1e9 + reward).toFixed(6))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (wallet && client) {
            referralUrl()
            getUserData()

            var intervalIns = setInterval(() => {
                calculationOfStake()
            }, 5000)
        }

        setIswalletConnected(tonConnectUI.connected)

        return () => {
            intervalIns && clearInterval(intervalIns)
        }
    }, [wallet, client])

    useEffect(() => {
        if (client) {
            getContractBalance()
            getTotalStaked()
        }
    }, [client])

    return (
        <>
            <div className='font-body mx-auto mb-4 w-full max-w-screen-lg text-[#7836B5] dark:text-dark-50'>
                <div className='mx-auto flex max-w-lg flex-row items-center px-4'>
                    <p className='text-lg font-bold'> statistics</p>
                    <a
                        href={import.meta.env.VITE_EXPLORAR_URL + import.meta.env.VITE_CONTRACT_ADDRESS}
                        target='_blank'
                        className='ml-auto text-xs font-light text-gray-300'
                    >
                        TON Explorer
                    </a>
                </div>

                <div className='mx-auto max-w-lg text-sm font-medium'>
                    <div className='m-4 rounded-2xl bg-white p-8 shadow-sm dark:bg-[#292626]'>
                        <div className='relative my-4 flex flex-row'>
                            <p>APY</p>
                            <img src={question} className='peer ml-1 w-4 dark:hidden' />
                            <img src={questionDark} className='peer ml-1 hidden w-4 dark:block' />
                            <p className='absolute left-1/3 top-6 z-10 hidden -translate-x-1/4 rounded-lg bg-[#484646] p-4 text-xs font-normal text-[#ffffff] shadow-xl peer-hover:block'>
                                Your yearly earnings based on recent staking rewards.
                            </p>
                            <p className='ml-auto'>60%</p>
                        </div>
                        <div className='relative my-4 flex flex-row'>
                            <p>Protocol fee</p>
                            <img src={question} className='peer ml-1 w-4 dark:hidden' />
                            <img src={questionDark} className='peer ml-1 hidden w-4 dark:block' />
                            <p className='absolute left-1/3 top-6 z-10 hidden -translate-x-1/4 rounded-lg bg-[#484646] p-4 text-xs font-normal text-[#ffffff] shadow-xl peer-hover:block'>
                                This fee is subtracted from generated validator rewards, NOT your staked amount.
                            </p>
                            <p className='ml-auto'>5%</p>
                        </div>
                        <div className='my-4 flex flex-row'>
                            <p>Currently staked</p>
                            <p className='ml-auto'>{totalStaked}</p>
                        </div>
                    </div>
                </div>
            </div>
            {isWalletConnected && (
                <div className='font-body mx-auto mb-16 w-full max-w-screen-lg text-[#7836B5] dark:text-dark-50'>
                    <div className='mx-auto mb-4 flex max-w-lg flex-row items-center justify-between px-4'>
                        <p className='text-lg font-bold'> Dashboard</p>
                    </div>

                    <div className='mx-auto max-w-lg text-sm font-medium'>
                        <div className='mx-4 rounded-2xl bg-white p-8 shadow-sm dark:bg-[#292626]'>
                            <>
                                <div className='my-4 flex flex-row'>
                                    <p>Total staked</p>
                                    <p className='ml-auto'>{convertAmount(userData?.totalStaked || 0n)}</p>
                                </div>
                                <div className='my-4 flex flex-row'>
                                    <p>Total reward</p>
                                    <p className='ml-auto'>{totalClaim || 0}</p>
                                </div>
                                <div className='my-4 flex flex-row'>
                                    <p>Refferal count</p>
                                    <p className='ml-auto'>{Number(userData?.referralCount) || 0}</p>
                                </div>
                                <div className='my-4 flex flex-row'>
                                    <p>Total refferal reward</p>
                                    <p className='ml-auto'>{convertAmount(userData?.referralReward || 0n)}</p>
                                </div>
                                <div className='items-center gap-4 pt-3 md:flex'>
                                    <button
                                        className={`h-10 w-full rounded-2xl ${userData && Number(totalClaim) <= 0 ?'bg-[#8e8c8c] cursor-not-allowed' :"bg-[#7836B5]"} text-sm font-medium text-white dark:text-white sm:w-80`}
                                        onClick={(e) => {
                                            try {
                                                if(userData && Number(totalClaim) > Number(contractBalance)){
                                                    return model.setErrorMessage('insufficient funds on contract.',1000)
                                                }
                                                const data = (): any => {
                                                    return 'claim_rewards_staked'
                                                }
                                                sendMessage(data(), '0.015')
                                            } catch (error) {
                                                console.log(error)
                                            }
                                        }}
                                        disabled={userData && Number(totalClaim) <= 0}
                                    >
                                        Claim Reward
                                    </button>
                                    <button
                                        className={`mt-2 h-10 w-full rounded-2xl  ${userData && (Number(userData.referralReward)/1e9) <= 0 ?'bg-[#8e8c8c] cursor-not-allowed' :"bg-[#7836B5]"} text-sm font-medium text-white dark:text-white sm:w-80 md:mt-0`}
                                        onClick={(e) => {

                                            try {
                                                if(userData && Number(userData.referralReward)/1e9 > Number(contractBalance)){
                                                    return model.setErrorMessage('insufficient funds on contract.',1000)
                                                }
                                                const data = (): any => {
                                                    return 'claim_referral'
                                                }
                                                sendMessage(data(), '0.015')
                                            } catch (error) {
                                                console.log(error)
                                            }
                                        }}
                                        disabled={userData && (Number(userData.referralReward)/1e9) <= 0}
                                    >
                                        Claim Refferal Reward
                                    </button>
                                </div>
                                <div className='mt-4'>
                                    <h3 className='text-center text-base font-bold'>Your Referral Link</h3>
                                    <div className='group mt-2 flex w-full flex-col items-center justify-center gap-4 sm:flex-row'>
                                        <input
                                            className='h-12 w-full grow overflow-hidden text-ellipsis whitespace-nowrap rounded-2xl bg-[#7836b542] p-4 text-xs font-light outline-none dark:text-white'
                                            readOnly
                                            onClick={(e) => {
                                                handleCopyRefLink()
                                                const target = e.target as HTMLInputElement
                                                target.parentElement?.classList.add('copied')
                                                clearTimeout(copiedTimeout)
                                                copiedTimeout = setTimeout(() => {
                                                    target.parentElement?.classList.remove('copied')
                                                }, 1000)
                                                target.select()
                                            }}
                                            value={refferalLink}
                                        />
                                        <button
                                            className='h-12 w-20 rounded-2xl bg-[#7836B5] text-lg text-white dark:text-dark-600'
                                            onClick={(e) => {
                                                handleCopyRefLink()
                                                const target = e.target as HTMLInputElement
                                                target.parentElement?.classList.add('copied')
                                                clearTimeout(copiedTimeout)
                                                copiedTimeout = setTimeout(() => {
                                                    target.parentElement?.classList.remove('copied')
                                                }, 1000)
                                            }}
                                        >
                                            <img
                                                src={copy}
                                                className='h10 pointer-events-none mx-auto group-[.copied]:hidden'
                                            />
                                            <img
                                                src={checkWhite}
                                                className='pointer-events-none mx-auto hidden h-10 group-[.copied]:block'
                                            />
                                        </button>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
})

export default Stats
